import Alpine from 'alpinejs';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import { Chart, ArcElement, PieController } from 'chart.js';

window.Alpine = Alpine;
window.tippy = tippy;
window.Chart = Chart;

Chart.register(ArcElement, PieController);

Alpine.directive('tooltip', (el, { expression }, { effect, cleanup }) => {
    tippy(el, { content: expression, allowHTML: true, maxWidth: 'none' });

    effect(() => el._tippy.setContent(expression));

    cleanup(() => el._tippy.destroy());
})

Alpine.start();
